// DataTable.js
import React from 'react';
import { Box } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';

const DataTable = ({ rows, columns, handleSelectionChange }) => (
    <div className="row">
        <Box m="20px">
            <Box m="40px 0 0 0" height="75vh">
                <DataGridPro
                    checkboxSelection
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row._id}
                    onRowSelectionModelChange={handleSelectionChange}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 100, page: 0 },
                        },
                    }}
                    pageSizeOptions={[100, 250, 1000]}
                />
            </Box>
        </Box>
    </div>
);

export default DataTable;
